import {
  Container,
  Heading,
  Divider,
  Text,
  Grid,
  GridItem,
  Center,
  HStack,
  Button,
  Link,
  Box,
} from "@chakra-ui/react";
import * as dayjs from "dayjs";
import { campInformation, packingInformation } from "../utils/CampInformation";
import colors from "../utils/color_codes";

export default function CampInformationSection({ section_name }) {
  return (
    <>
      <Container
        maxW={"80%"}
        centerContent
        textColor={"#333333"}
        py={"2rem"}
        // display={"flex"}
        // flexDirection={"column"}
        id={campInformation[section_name]?.id}
      >
        <Heading as={"h3"} size={"xl"} mb={"1rem"} textAlign={"center"}>
          {campInformation[section_name].camp_title}
        </Heading>
        <Divider />
        <Text mt={"0.5rem"} mb={"1rem"} as="b" color="red" fontSize="2xl">
          {/* <Heading as="h3" size={"lg"} color="#f1807e"> */}
          {/* {dayjs().isBefore(dayjs("2023-12-18 23:59")) && (
            <Link href={campInformation[section_name].registration_link}>
              {campInformation[section_name].registration_information}
            </Link>
          )} */}
          {/* </Heading> */}
        </Text>
        <Grid templateColumns="repeat(3, 1fr)" gap={2} width={"100%"}>
          {campInformation[section_name].informational_blobs.map((item) => {
            return (
              <GridItem
                maxW="100%"
                margin={"auto"}
                colSpan={{ base: 6, lg: 1, xl: 1 }}
                py={"0.5rem"}
                textAlign={"center"}
              >
                <Container maxW={"100%"}>
                  <Text as={"b"} fontSize="lg">
                    {item.title}
                  </Text>
                  <Divider mb={"0.5rem"} />
                  <Text>{item.data}</Text>
                </Container>
              </GridItem>
            );
          })}
        </Grid>
        {campInformation[section_name].camp_title === "WINTER CAMP 2024" ? (
          <Center pt={"1rem"}>
            <HStack>
              <Link href="/register">
                <Button
                  href="/register"
                  color={"#2C3E50"}
                  backgroundColor={colors.secondaryBackgroundColor}
                  _hover={{
                    backgroundColor: colors.secondaryBackgroundColor,
                    // color: "white",
                    opacity: 0.7,
                  }}
                >
                  Register Now!
                </Button>
              </Link>
            </HStack>
          </Center>
        ) : (
          ""
        )}
        {/* <Center>
          <HStack>
            <Button>Register Now</Button>
            <Button></Button>
          </HStack>
        </Center> */}
      </Container>
      <Container
        maxW={"100%"}
        // textColor={"#333333"}
        py={"2rem"}
        mb={"2rem"}
        bgColor={colors.mainBackgroundColor}
        display={"flex"}
        // flexDirection={"column"}
        textColor={colors.textColor}
        id={campInformation[section_name]?.id}
      >
        <Container maxW={"70%"}>
          <Heading as={"h3"} size={"xl"} mb={"1rem"} textAlign={"center"}>
            Camper Packing List
          </Heading>
          <Text mt={"1rem"} mb={"1rem"} as="b" fontSize="2xl">
            General Items
          </Text>
          <Box as="ul" listStyleType="circle">
            {packingInformation["General"].map((value) => {
              return (
                // <li>Item 1</li>
                <li mt={"0.5rem"} mb={"1rem"} as="b" fontSize="2xl">
                  {value}
                </li>
              );
            })}

            {/* <li>Item 2</li>
            <li>Item 3</li> */}
          </Box>
          <Text mt={"1rem"} mb={"1rem"} as="b" fontSize="2xl">
            Optional Items
          </Text>
          <Box as="ul" listStyleType="circle">
            {packingInformation["Optional"].map((value) => {
              return (
                // <li>Item 1</li>
                <li mt={"0.5rem"} mb={"1rem"} as="b" fontSize="2xl">
                  {value}
                </li>
              );
            })}

            {/* <li>Item 2</li>
            <li>Item 3</li> */}
          </Box>
          <Text
            mt={"1rem"}
            mb={"1rem"}
            as="b"
            color={colors.announcementColor}
            fontSize="2xl"
          >
            Out of state campers, please let us know if you will need us to
            provide bedding
          </Text>
        </Container>
        {/* <Divider /> */}
      </Container>
    </>
  );
}
