import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./views/HomePage";
import { ChakraProvider } from "@chakra-ui/react";
import RegistrationPage from "./views/RegistrationPage";
import ParentInfoPage from "./views/ParentInfoPage";
import { Toaster } from "react-hot-toast";
import RegisteredCampersView from "./views/RegisteredCampersView";
import LoginPage from "./views/LoginPage";
import AdminFlightView from "./views/AdminFlightView";
import SingleCamperPage from "./views/SingleCamperPage";
import * as dayjs from "dayjs";

function App() {
  return (
    <ChakraProvider>
      <div className="App">
        <Toaster
          position="top-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              textAlign: "left",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            {dayjs().isBefore(dayjs("2025-12-18 23:59")) && (
              <Route path="/register" element={<RegistrationPage />}></Route>
            )}

            <Route
              path="/admin/campers"
              element={<RegisteredCampersView />}
            ></Route>
            <Route path="/admin/flights" element={<AdminFlightView />}></Route>
            <Route path="/admin/login" element={<LoginPage />}></Route>
            <Route
              path="/admin/camper/:camper_id"
              element={<SingleCamperPage id="test_id" />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
