import axios from "axios";
import { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Button,
  Box,
  Input,
} from "@chakra-ui/react";
import { CSVLink, CSVDownload } from "react-csv";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
export default function CamperTable({ campers, callback_request }) {
  // _id	birthDate	camperFlying	camperPayment	city	day	doctorsName	doctorsNumber	email	fatherName	fatherNumber	firstName	flightInformation	gender	grade	lastName	medicalConditions	month	motherName	motherNumber	paid	regularMedications	shirtSize	state	status	streetAddress	year	zip
  const navigate = useNavigate();
  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Gender", key: "gender" },
    { label: "Flying", key: "camperFlying" },
    { label: "Birthday", key: "birthDate" },
    { label: "Grade", key: "grade" },
    { label: "Email", key: "email" },
    { label: "Mother Name", key: "motherName" },
    { label: "Mother Number", key: "motherNumber" },
    { label: "Father Name", key: "fatherName" },
    { label: "Father Number", key: "fatherNumber" },
    { label: "Medical Conditions", key: "medicalConditions" },
    { label: "Regular Medications", key: "regularMedications" },
    { label: "Shirt Size", key: "shirtSize" },
    { label: "Address", key: "streetAddress" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Zip", key: "zip" },
  ];
  const [filteredCampers, setFilteredCampers] = useState(campers);
  const filterCampers = (e) => {
    if (e.target.value === "") {
      setFilteredCampers(campers);
      return;
    }
    let returned_list = [];
    campers.map((camper) => {
      if (camper.firstName.includes(e.target.value)) {
        console.log("match found");
        returned_list.push(camper);
      }
    });
    console.log(returned_list);
    setFilteredCampers(returned_list);
  };

  useEffect(() => {
    setFilteredCampers(campers);
  }, [campers]);
  return (
    <>
      <Box
        maxW={"75%"}
        style={{
          overflowY: "auto",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Input
          placeholder="First Name"
          onChange={(e) => filterCampers(e)}
          my={"1rem"}
        />
        {campers && filteredCampers && (
          <CSVLink
            data={filteredCampers}
            filename={"campers.csv"}
            headers={headers}
          >
            <Button
              isLoading={false}
              loadingText="Exporting"
              colorScheme="teal"
              variant="outline"
              mb="1rem"
              onClick={() => {}}
              _hover={{ cursor: "pointer" }}
            >
              Export
            </Button>
          </CSVLink>
        )}
      </Box>

      <TableContainer
        maxW={"75%"}
        maxH={"50vh"}
        style={{
          overflowY: "auto",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Table variant="simple">
          <TableCaption>HSYC 2023 Registered Campers</TableCaption>
          <Thead
            position="sticky"
            top={0}
            bg="#2B2D42"
            textColor="#fff"
            zIndex={6}
          >
            <Tr>
              <Th color={"white"}>First Name</Th>
              <Th color={"white"}>Last Name</Th>
              <Th color={"white"}>Gender</Th>
              <Th color={"white"}>DOB</Th>
              <Th color={"white"}>Age</Th>
              <Th color={"white"}>Grade</Th>
              <Th color={"white"}>Camper Status</Th>
              <Th color={"white"}>Flying in?</Th>
              <Th color={"white"}>Paid?</Th>
            </Tr>
          </Thead>
          <Tbody bg={"#dee2e6"}>
            {filteredCampers?.map((camper) => {
              return (
                <Tr
                  _hover={{
                    opacity: 0.5,
                    backgroundColor: "lightgrey",
                    color: "black",
                    cursor: "pointer",
                  }}
                  key={camper._id}
                  onClick={() => {
                    navigate(`/admin/camper/${camper._id}`);
                  }}
                >
                  <Td>{camper.firstName}</Td>
                  <Td>{camper.lastName}</Td>
                  <Td>{camper.gender}</Td>
                  <Td>
                    {camper.month}/{camper.day}/{camper.year}
                  </Td>
                  <Td>
                    {dayjs().diff(
                      dayjs(`${camper.year}-${camper.month}-${camper.day}`),
                      "year"
                    )}
                  </Td>
                  <Td>{camper.grade}</Td>
                  <Td>{camper.status}</Td>
                  <Td>{camper.flightInformation ? "Yes" : "No"}</Td>
                  <Td
                    // bg={"#1a1a1a"}
                    onClick={(e) => {
                      // var camper_information1 =
                      // e.target.innerHTML = "Updating";
                      axios
                        .put(
                          "https://api.houstonsikhyouthcamp.org/camper",
                          {
                            camper_information: { camper },
                            camper_paid: !camper.paid,
                            camper_id: camper._id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${localStorage.getItem(
                                "token"
                              )}`,
                            },
                          }
                        )
                        .then((res) => {
                          callback_request(true);
                        });
                    }}
                  >
                    <Button
                      w="50%"
                      sx={{
                        backgroundColor: camper.paid ? "#92e6a7" : "#e5383b",
                      }}
                    >
                      {camper.paid ? "Yes" : "No"}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
            {/* {campers?.map((c) => {
              //   console.log(c);
              if (c?.campersInformation) {
                return c.campersInformation?.map((camper) => {
                  return (
                    <Tr
                      _hover={{
                        opacity: 0.5,
                        backgroundColor: "lightgrey",
                        color: "black",
                        cursor: "pointer",
                      }}
                    >
                      <Td>{camper.firstName}</Td>
                      <Td>{camper.gender}</Td>
                      <Td>
                        {camper.month}/{camper.day}/{camper.year}
                      </Td>
                      <Td>
                        {dayjs().diff(
                          dayjs(`${camper.year}-${camper.month}-${camper.day}`),
                          "year"
                        )}
                      </Td>
                      <Td>{c.guardianInformation.camperFlying}</Td>
                      <Td
                        onClick={() => {
                          // var camper_information1 =
                          axios.put("http://localhost:5000/camper", {
                            camper_information: {},
                          });
                        }}
                      >
                        {"No"}
                      </Td>
                    </Tr>
                  );
                });
              }
            })} */}
          </Tbody>
          <Tfoot>
            {/* <Tr>
              <Th>To convert</Th>
              <Th>into</Th>
              <Th isNumeric>multiply by</Th>
            </Tr> */}
          </Tfoot>
        </Table>
      </TableContainer>
    </>
  );
}
